<template>
  <div class="container" v-title :data-title="$t('i18n.operationRecord')">
    <div id="outer-title">{{ $t("i18n.operationRecord") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
import { useRouter } from "vue-router";
export default {
  name: "OperationRecord",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const { t } = useI18n();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "elevatorShortNumber",
          label: "abbreviation",
          align: "center",
        },
        {
          prop: "generateTime",
          label: "reportingtime",
          align: "center",
        },
        {
          prop: "processTime",
          label: "operationTime",
          align: "center",
        },
        {
          prop: "status",
          label: "status",
          rows: false,
          search: true,
          type: "select",
          data: [
            { locale: "processed", value: "PROCESSED" },
            { locale: "ignored", value: "IGNORE" },
            { locale: "untreated", value: "PENDING" },
          ],
          props: { label: "locale", value: "value" },
        },
        {
          prop: "processUser",
          label: "operator",
          align: "center",
          search: true,
          type: "input",
        },
      ],
      operates: {
        // 列操作按钮
        width: 200,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "view",
            icon: "el-icon-view",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              dealFaultMsg(row);
            },
          },
          {
            id: "2",
            label: "ignore",
            icon: "el-icon-delete",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              ignoreMsg(row.id);
            },
          },
        ],
      },
      total: 0,
      loading: true,
      editData: {},
    });

    const init = async (params) => {
      state.loading = true;
      params.messageType = "FAULT_MESSAGE";
      const { data } = await proxy.$api.rescue.getOperationRecord(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const ignoreMsg = (id) => {
      // 忽略
      let callback = () => {
        var messageIds = [];
        messageIds.push(id);
        proxy.$api.common.ignoreMsg(messageIds).then(() => {
          init(state.formInline);
          proxy.$defined.tip(t("i18n.ignore") + t("i18n.success"), "success");
        });
      };
      proxy.$defined.confirm(
        callback,
        t("i18n.doYouWantToIgnore"),
        t("i18n.ignore")
      );
    };

    const dealFaultMsg = async (item) => {
      // 查看处理故障
      await proxy.$api.common.dealFaultMsg(item.id, { message: "PROCESSED" });
      let routeData = router.resolve({
        path: "/realTime/detail",
        query: { id: item.url }, // url 电梯id
      });
      window.open(routeData.href, "_blank");
    };

    return {
      ...toRefs(state),
      init,
      ignoreMsg,
    };
  },
};
</script>
